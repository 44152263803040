.iziModal
  max-width vw(500)!important
  @media $xs-and-smaller
    max-width 100%!important
.modal
  padding vw(60) vw(30) vw(30)
  @media $xs-and-smaller
    padding mvw(50) mvw(25) mvw(25)
  .form__this
    width 100%
    padding 0
    flex-direction column
  .form__label
    width 100%
    margin 0 0 vw(15)
    display flex
    justify-content center
    @media $xs-and-smaller
      margin 0 0 mvw(15)
  .link_global
    margin-top vw(15)
    @media $xs-and-smaller
      margin-top mvw(15)

.close
  size(vw(30))
  padding 0
  background none
  border none
  display flex
  justify-content center
  align-items center
  position absolute
  top vw(15)
  right vw(20)
  @media $xs-and-smaller
    size(mvw(25))
    top mvw(15)
    right mvw(20)
  &:hover > svg
    fill baseGrayText
  svg
    size(100%)
    fill baseOrange
    transition()
