.text_50
	font-size vw(50)
	font-family titleFont
	font-weight normal
	@media $xs-and-smaller
		font-size mvw(40)

.text_32
	font-size vw(32)
	font-family titleFont
	font-weight normal
	@media $xs-and-smaller
		font-size mvw(22)

.text_26
	font-size vw(26)
	@media $xs-and-smaller
		font-size mvw(22)

.text_24
	font-size vw(24)
	@media $xs-and-smaller
		font-size mvw(20)

.text_22
	font-size vw(22)
	@media $xs-and-smaller
		font-size mvw(18)

.text_20
	font-size vw(20)
	@media $xs-and-smaller
		font-size mvw(16)

.text_18
	font-size vw(18)
	@media $xs-and-smaller
		font-size mvw(14)

.text_16
	font-size vw(16)
	@media $xs-and-smaller
		font-size mvw(12)