@import 'utils/fonts.styl'
@import 'utils/vars.styl'
@import 'utils/mixins.styl'

body
  font-family mainFont
  font-weight normal
  line-height 1.3
  font-size baseFontSize
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-rendering optimizeLegibility
  background-color #FFFBF6
  opacity 0
  transition()

h1, h2, h3, h4, h5, h6
  margin 0

ul, li
  list-style-type none
  margin 0
  padding 0

p
  margin 0

a
  color black
  text-decoration none

a:hover
  text-decoration none

audio,
canvas,
iframe,
img,
svg,
video
  vertical-align middle

textarea
  resize none

section
  position relative

input,
select,
button,
textarea
  outline none !important

*, *:before, *:after
  box-sizing border-box

.wrapper
  min-height 100vh
  display flex
  flex-direction column
  overflow hidden

.content
  flex 1 0 auto

// VW bootstrap grid system
.container
  max-width vw(1600)
  padding-right vw(15)
  padding-left vw(15)
  @media $xs-and-smaller
    max-width 100%
    padding-right mvw(15)
    padding-left mvw(15)

.container-fluid
  padding-right vw(15)
  padding-left vw(15)
  @media $xs-and-smaller
    padding-right mvw(15)
    padding-left mvw(15)

.row
  margin-left vw(-15)
  margin-right vw(-15)
  @media $xs-and-smaller
    margin-left mvw(-15)
    margin-right mvw(-15)

.col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12
  padding-left vw(15)
  padding-right vw(15)
  @media $xs-and-smaller
    padding-left mvw(15)
    padding-right mvw(15)

//Модули
@import "modules/modules.styl"

// СТРАНИЦЫ
// Главная страница
@import 'pages/index/index.styl'