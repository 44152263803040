.form
	width 100%
	min-height vw(580)
	@media $xs-and-smaller
		min-height mvw(740)
		padding-top mvw(50)

	&_wrap
		width 100%
		background url("../img/general/bg_form.png") no-repeat top center
		background-size cover

	&__title
		width 100%
		text-align center
		padding-bottom vw(20)
		@media $xs-and-smaller
			padding-bottom mvw(20)

	&__sub:nth-child(3)
		color baseOrange

	&__sub
		width 100%
		font-weight 600
		text-align center
		line-height 1.5
		text-shadow vw(-1) 0 baseWhite, 0 vw(1) baseWhite, vw(1) 0 baseWhite, 0 vw(-1) baseWhite
		@media $xs-and-smaller
			text-shadow mvw(-1) 0 baseWhite, 0 mvw(1) baseWhite, mvw(1) 0 baseWhite, 0 mvw(-1) baseWhite


	&__this
		padding-top vw(40)
		width 100%
		display flex
		justify-content center
		align-items center
		@media $xs-and-smaller
			padding-top mvw(30)
			flex-direction column
			justify-content flex-start

	&__label
		margin 0 vw(13.5)
		size(vw(320), vw(60))
		position relative
		@media $xs-and-smaller
			size(100%, mvw(50))
			margin 0 0 mvw(25)
		b
			position absolute
			bottom vw(3)
			left vw(20)
			color red
			font-weight 600
			font-size vw(12)
			@media $xs-and-smaller
				bottom mvw(3)
				left mvw(20)
				font-size mvw(10)

	&__input::placeholder
		color baseGrayText

	&__input
		size(100%)
		padding 0 vw(20)
		border vw(1) solid baseGrayText
		background-color baseWhite
		font-weight 600
		color baseBlack
		border-radius vw(5)
		@media $xs-and-smaller
			padding 0 mvw(20)
			border mvw(1) solid baseGrayText
			border-radius mvw(5)

	.link_global
		size(100%)

.map
	size(100%, vw(300))
	box-shadow vw(4) 0 vw(25) rgba(0,0,0,.25)
	@media $xs-and-smaller
		height mvw(300)
		box-shadow mvw(4) 0 mvw(25) rgba(0,0,0,.25)
	&_wrap
		width 100%
		margin-top vw(-200)
		@media $xs-and-smaller
			margin-top mvw(-75)
			.container
				padding 0

.leaflet-popup-content
	text-align center
	font-family titleFont
	color baseOrange