.products
  width 100%
  padding 0 vw(60) vw(120)
  @media $xs-and-smaller
    padding mvw(30) 0

  &_wrap
    width 100%
    position relative
    background url("../img/general/bg_body.png")
    background-color baseWhite
    background-repeat repeat
    background-position 50% vw(-250)
    background-size contain

    &:after
      content ""
      position absolute
      bottom 0
      left 0
      transform rotate(180deg)
      size(100%, vw(180))
      background: linear-gradient(180deg, #FEFAF5 8.72%, rgba(255, 255, 255, 0) 80%);
      z-index 90

    &:before
      content ""
      position absolute
      top vw(-98)
      left 50%
      transform translateX(-50%) rotate(180deg)
      size(vw(1570), vw(100))
      background url("../img/general/bg_body.png")
      background-color baseWhite
      background-repeat repeat
      background-position 50% 72%
      background-size cover
      z-index 90
      @media $xs-and-smaller
        content none

  &__title
    padding 0 0 vw(62)
    text-align center
    @media $xs-and-smaller
      padding-bottom mvw(30)

.product
  margin-bottom vw(55)
  width 100%
  background baseWhite
  box-shadow: 0 0 vw(20) rgba(0, 0, 0, .2);
  border-radius vw(5)
  @media $xs-and-smaller
    margin-bottom mvw(30)
    box-shadow: 0 0 mvw(20) rgba(0, 0, 0, .2);
    border-radius mvw(5)

  &__img
    size(100%)
    object-fit contain
    object-position center center

  &__btn
    padding 0
    size(100%, vw(244))
    display flex
    justify-content space-between
    align-items center
    border none
    background-color baseWhite
    cursor pointer
    @media $xs-and-smaller
      height mvw(244)
      flex-direction column
      padding mvw(15)
      text-align center
      br
        display none

    &__wrap
      width 100%

    &__img
      width 30%
      height 100%
      padding vw(20)
      @media $xs-and-smaller
        size(mvw(150))

    &__title
      width 65%
      position relative
      display flex
      justify-content space-between
      align-items center
      @media $xs-and-smaller
        width 100%
        flex-direction column
        justify-content flex-start

      &:before
        content ""
        position absolute
        top 50%
        left 0
        width 100%
        height 1px
        background baseBrownLight
        z-index 10
        @media $xs-and-smaller
          content none

      .text_32
        position relative
        z-index 50
        background baseWhite
        color baseGrayText
        padding 0 vw(20) 0 vw(30)
        @media $xs-and-smaller
          padding 0

      .text_18
        background baseWhite
        position relative
        z-index 50
        color baseOrange
        font-weight 600
        padding 0 vw(50) 0 vw(20)
        @media $xs-and-smaller
          padding mvw(10) 0 0 0

    &__svg
      width 5%
      @media $xs-and-smaller
        display none

      svg
        size(vw(27))
        fill baseGrayLight

  &__info
    display flex
    flex-wrap wrap
    min-height vw(244)
    @media $xs-and-smaller
      min-height initial
      height auto

    &__wrap
      padding vw(40) 0 vw(60)
      width 100%
      display none
      @media $xs-and-smaller
        padding 0 0 mvw(30)

    &__title
      @extend .product__btn__title
      width 100%
      margin vw(30) 0 vw(65)
      @media $xs-and-smaller
        margin mvw(30) 0

      .text_32
        padding 0 vw(20) 0 vw(93)
        @media $xs-and-smaller
          padding 0 mvw(15)
          br
            display none

      .text_18
        height vw(30)
        padding 0 vw(100) 0 vw(20)
        @media $xs-and-smaller
          height auto
          padding 0 mvw(30)

      svg
        position absolute
        top 50%
        right vw(45)
        transform translateY(-50%) rotate(-180deg)
        size(vw(27))
        fill baseGrayLight
        z-index 80
        @media $xs-and-smaller
          display none

  &__slider
    width 35%
    @media $xs-and-smaller
      width 100%

    &__img
      width 100%

  &__main
    width 65%
    @media $xs-and-smaller
      width 100%

  &__checkbox
    width 100%
    padding vw(60) vw(118) vw(60) vw(55)
    @media $xs-and-smaller
      padding 0 mvw(15) mvw(30)

  &__text
    padding 0 vw(118) 0 vw(93)
    color baseGrayText
    line-height 1.5
    padding-bottom vw(35)
    @media $xs-and-smaller
      padding 0 mvw(15) mvw(30)

  &__nav
    padding 0 vw(55)
    display flex
    justify-content space-between
    align-items center
    width 100%
    height vw(74)
    position relative
    background baseGrayLight
    z-index 10
    @media $xs-and-smaller
      padding 0 mvw(15)
      height mvw(50)

  &__submit
    width 100%
    display flex
    justify-content center
    align-items center

    .link_global
      padding-left vw(70)
      padding-right vw(70)
      @media $xs-and-smaller
        padding-left mvw(70)
        padding-right mvw(70)

.button-nav
  size(vw(38))
  padding 0
  border-radius 50%
  background-color baseWhite
  border none
  cursor pointer
  z-index 30
  position relative
  @media $xs-and-smaller
    size(mvw(30))

  &__svg
    absolute-center()
    fill baseOrange
    size(125%)

.button-next
  transform rotate(-90deg)

.button-prev
  transform rotate(90deg)

.swiper-pagination-fraction
  bottom initial
  top 50%
  transform translateY(-50%)
  font-size vw(24)
  font-family titleFont
  letter-spacing vw(-2)

.checkbox:not(:last-child)
  margin-bottom vw(25)
  @media $xs-and-smaller
    margin-bottom mvw(25)

.checkbox
  display flex
  justify-content flex-start
  align-items center
  @extend .product__btn__title
  width 100%
  @media $xs-and-smaller
    flex-direction column
    align-items flex-start

  &__label
    display flex
    justify-content flex-start
    align-items center
    font-family titleFont
    color baseGrayText
    margin-bottom 0
    cursor pointer
    background-color baseWhite
    padding-right vw(40)
    position relative
    z-index 30
    @media $xs-and-smaller
      width 100%
      padding mvw(5) 0
      padding-right 0
      border-top 1px solid baseGrayText
      border-bottom 1px solid baseGrayText

  &__radio
    margin-right vw(12)
    @media $xs-and-smaller
      margin-right mvw(12)

  &__price
    color baseGrayText
    position absolute
    top 50%
    right 0
    transform translateY(-50%)
    padding-left vw(20)
    z-index 80
    background-color baseWhite
    font-weight 600
    @media $xs-and-smaller
      position static
      background-color transparent
      transform initial
      padding-left 0
      padding-top mvw(10)
      text-align center
      display block
      width 100%
      text-align center

    i
      margin-left vw(5)
      color baseOrange
      font-style normal
      @media $xs-and-smaller
        margin-left mvw(5)

  &__sale
    padding 0 vw(20)
    display inline-block
    position absolute
    top 50%
    left 41%
    transform translateY(-50%)
    z-index 80
    background-color baseWhite
    color baseOrange
    @media $xs-and-smaller
      padding 0
      position static
      transform initial
      background-color transparent
      font-size mvw(14)
      padding-top mvw(10)
      display block
      width 100%
      text-align center

    b
      margin 0 vw(5)
      font-weight 600
      @media $xs-and-smaller
        margin 0 mvw(5)

.slide_link
  display block
  size(100%)
  outline none !important

.counter
  display inline-block
  font-family titleFont
