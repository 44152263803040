@font-face {
  font-family: 'Open Sans';
  font-display: block;
  src: url('../fonts/OpenSans-Regular.eot');
  src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Regular.woff2') format('woff2'),
  url('../fonts/OpenSans-Regular.woff') format('woff'),
  url('../fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  font-display: block;
  src: url('../fonts/OpenSans-SemiBold.eot');
  src: url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
  url('../fonts/OpenSans-SemiBold.woff') format('woff'),
  url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
  
@font-face {
  font-family: 'Roboto Slab';
  font-display: block;
  src: url('../fonts/RobotoSlab-Regular.eot');
  src: url('../fonts/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoSlab-Regular.woff2') format('woff2'),
  url('../fonts/RobotoSlab-Regular.woff') format('woff'),
  url('../fonts/RobotoSlab-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


