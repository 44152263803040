.welcome
	width 100%
	min-height vw(860)
	color baseWhite
	text-align center
	@media $xs-and-smaller
		min-height initial
		height 100vh
		display flex
		flex-direction column
		justify-content center
		align-items center
		padding-top mvw(130)
	@media $xxs-and-smaller
		height auto
		padding-top mvw(170)

	&_wrap
		width 100%
		background-repeat no-repeat
		background-position center center
		background-size cover

	&__nav
		width 100%
		padding vw(170) 0 vw(60)
		@media $xs-and-smaller
			position fixed
			top 0
			left mvw(-280)
			padding mvw(30)
			background-color baseBrownLight
			z-index 100
			width mvw(280)
			height 100vh
			transition()

	&__title
		width 100%
		font-weight 400
		@media $xs-and-smaller
			padding-bottom mvw(20)

	&__list
		padding-top vw(102)
		width 100%
		display flex
		justify-content center
		align-items center
		@media $xs-and-smaller
			padding-top mvw(50)
			flex-direction column

		.text_18
			position relative
			z-index 20
			font-weight 600

	&__item
		margin 0 vw(35)
		position relative
		@media $xs-and-smaller
			margin 0 0 mvw(80) 0

		&:before
			content ""
			absolute-center()
			background-repeat no-repeat
			background-size contain
			background-position center center
			z-index 1

	&__item:nth-child(1)
		&:before
			size(vw(89))
			background-image url("../img/svg/icon_welcome1.svg")
			@media $xs-and-smaller
				size(mvw(60))

	&__item:nth-child(2)
		&:before
			size(vw(170), vw(73))
			background-image url("../img/svg/icon_welcome2.svg")
			@media $xs-and-smaller
				size(mvw(120), mvw(45))

	&__item:nth-child(3)
		&:before
			size(vw(122))
			top 10%
			background-image url("../img/svg/icon_welcome3.svg")
			@media $xs-and-smaller
				size(mvw(80))

.nav
	width 100%
	display flex
	flex-wrap wrap
	justify-content space-between
	@media $xs-and-smaller
		flex-direction column

	&__item
		width 23%
		text-align center
		margin-bottom vw(50)
		@media $xs-and-smaller
			width 100%!important
			text-align left
			margin-bottom mvw(30)

	&__item:nth-child(1), &__item:nth-child(5)
		width 7%

	&__item:nth-child(4), &__item:nth-child(8)
		width 23%

	&__item:nth-child(2), &__item:nth-child(6)
		width 52%

	&__item:nth-child(3), &__item:nth-child(7)
		width 18%

	&__link
		color baseWhite
		transition()
		font-weight 600
		position relative

		&:before
			content ""
			position absolute
			bottom vw(-15)
			left 50%
			transform translateX(-50%)
			size(vw(6))
			background-color baseWhite
			border-radius vw(3)
			transition()
			@media $xs-and-smaller
				content none

		&:hover:before
			width vw(40)
			background-color baseOrange

		&:hover
			color baseOrange

.nav__mobile
	left 0

.overlay
	display none
	@media $xs-and-smaller
		display block
		size(100%)
		position fixed
		top 0
		left -100%
		background-color baseOrange
		opacity .4
		z-index 99
		transition()

.overlay__active
	left 0