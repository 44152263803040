header
  position absolute
  top 0
  left 0
  width 100%
  z-index 90
  background url("../img/svg/bg_header.svg") no-repeat center center
  background-size cover

.header
  position relative
  size(100%, vw(150))
  padding-bottom vw(33)
  display flex
  justify-content space-between
  align-items center
  @media $xs-and-smaller
    height mvw(90)
    padding-bottom mvw(17)

  &__lang
    display flex
    align-items center
    justify-content flex-start
  &__link
    display flex
    justify-content flex-end
    align-items center
    @media $xs-and-smaller
      display none
  &__logo
    position absolute
    left 50%
    transform translateX(-50%)
    bottom vw(-60)
    size(vw(180))
    background baseWhite url("../img/svg/logo.svg") no-repeat center center
    background-size 90%
    border-radius 50%
    box-shadow 0 0 vw(4) #2E1616
    z-index 100
    @media $xs-and-smaller
      size(mvw(105))
      box-shadow 0 0 mvw(4) #2E1616
      bottom mvw(-35)
  .phone
    @media $xs-and-smaller
      display none

.lang
  position relative
  &__this
    position relative
    size(vw(49), vw(39))
    display flex
    justify-content center
    align-items center
    background url("../img/svg/lang-border.svg") no-repeat center center
    background-size contain
    @media $xs-and-smaller
      size(mvw(49), mvw(39))
    &:before
      content ""
      position absolute
      top 48%
      right vw(-6)
      size(vw(13))
      transform translateY(-50%)
      background url("../img/svg/drop_lang.svg") no-repeat center center
      background-size contain
      transition()
      @media $xs-and-smaller
        right mvw(-6)
        size(mvw(13))
  &__other
    width vw(49)
    padding vw(5) 0
    display none
    position absolute
    top 115%
    left 50%
    transform translateX(-50%)
    background-color baseBrownLight
    border vw(1) solid baseBrownLight
    border-radius vw(7)
    @media $xs-and-smaller
      border-width mvw(1)
      width mvw(49)
      padding mvw(5) 0
      border-radius mvw(7)
  &__active
    color baseWhite
    font-weight 600
    transition()
    &:hover
      color baseOrange
  &__link
    display block
    text-align center
    line-height 1.5
    font-weight 600
    color baseBlack
    transition()
    @media $xs-and-smaller
      line-height 2
    &:hover
      color baseOrange
  &__toggle
    &:before
      transform translateY(-50%) rotate(180deg)

.phone
  margin-left vw(75)
  display flex
  justify-content flex-end
  align-items center
  font-weight 600
  color baseWhite
  transition()
  @media $xs-and-smaller
    margin-left 0
    margin-bottom mvw(20)
  &__svg
    size(vw(22))
    fill baseBrownLight
    margin-right vw(12)
    transition()
    @media $xs-and-smaller
      size(mvw(17))
      margin-right mvw(10)
  &:hover > svg
    fill baseOrange
  &:hover
    color baseOrange

.time_jobs
  margin-right vw(50)
  display flex
  justify-content flex-start
  align-items center
  &__title
    display flex
    justify-content flex-start
    align-items center
  &__svg
    margin-right vw(12)
    size(vw(22))
    fill baseBrownLight
  &__main
    padding-left vw(13)
    display flex
    flex-direction column
    justify-content center
    align-items flex-start
  .text_18
    color baseWhite
    font-weight 600

.link_global
  padding 0 vw(38)
  height vw(55)
  display flex
  justify-content center
  align-items center
  color baseWhite
  background-color baseOrange
  border-radius vw(5)
  font-weight 600
  overflow hidden
  transform-style preserve-3d
  border none
  @media $xs-and-smaller
    padding 0 mvw(50)
    height mvw(45)
    border-radius mvw(5)
  &:hover
    color baseWhite
  &__text
    transform translateZ(vw(20))

.burger
  display none
  @media $xs-and-smaller
    size(mvw(30))
    padding 0
    display flex
    justify-content center
    align-items center
    background none
    border none
    &__svg
      size(100%)
      fill baseWhite
      transform rotate(-90deg)
      transition()

.burger__active
  svg
    transform rotate(0)

.overflow
  overflow hidden!important
