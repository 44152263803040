footer
  flex 0 0 auto
  width 100%

.footer
  width 100%
  &__contacts
    width 100%
    display flex
    justify-content space-between
    align-items center
    padding vw(135) 0
    position relative
    @media $xs-and-smaller
      padding mvw(160) 0 mvw(10)
      flex-direction column
  &__phone
    display flex
    justify-content flex-start
    align-items center
    @media $xs-and-smaller
      flex-direction column
  &__address
    display flex
    justify-content flex-end
    align-items center
    @media $xs-and-smaller
      flex-direction column
      justify-content flex-start
  &__copyright
    size(100%, vw(131))
    display flex
    justify-content space-between
    align-items center
    border-top vw(2.2) solid rgba(98, 89, 80, 0.2)
    color rgba(98,89,80,.5)
    @media $xs-and-smaller
      height auto
      border-width mvw(2)
      flex-direction column
      padding mvw(25) 0
  &__design
    display flex
    justify-content flex-end
    align-items center
    @media $xs-and-smaller
      flex-direction column
      .text_16
        margin-top mvw(15)
  .phone
    color baseGrayText
    margin-left 0
    margin-right vw(150)
    @media $xs-and-smaller
      margin-right 0
    &:hover
      color baseOrange
    span
      color baseOrange
      margin-left vw(15)
  &__logo
    size(vw(131))
    position absolute
    top 50%
    left 50%
    transform translate(-50%,-50%)
    background url("../img/svg/logo.svg") no-repeat center center
    background-size contain
    @media $xs-and-smaller
      size(mvw(100))
      top mvw(85)

.email, .address
  @extend .phone
  color baseGrayText
  margin-left vw(48)
  @media $xs-and-smaller
    margin-left 0
    margin-bottom mvw(20)
  svg
    fill baseGrayText

.galaxy
  display block
  margin-left vw(18)
  size(vw(122), vw(48.13))
  background url("../img/svg/logo-galaxy.svg") no-repeat center center
  background-size contain
  @media $xs-and-smaller
    margin-left 0
    margin-top mvw(15)
    size(mvw(122), mvw(48.13))
